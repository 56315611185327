import "./app.css";
import React, { useEffect, useState } from "react";
import { connectWallet, getCurrentWalletConnected } from "./utils/interact";
import { walletSlice } from "./Slices/Wallet";
import { nftSlice } from "./Slices//Nft";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import Layout from "./components/Layout/Layout";

function App() {
  const imageDetail = useSelector((state) => state.image.imageDetail);
  console.log("inside FileUpload" + imageDetail);
  const dispatch = useDispatch();
  const walletActions = walletSlice.actions;
  const nftActions = nftSlice.actions;
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          dispatch(walletActions.setWalletAddress(accounts[0]));

          setStatus("👆🏽 Write a message in the text-field above.");
        } else {
          setWallet("");
          setStatus("🦊 Connect to Metamask using the top right button.");
        }
      });
    } else {
      setStatus(
        <p>
          {" "}
          🦊{" "}
          <a target="_blank" href={`https://metamask.io/download.html`}>
            You must install Metamask, a virtual Ethereum wallet, in your
            browser.
          </a>
        </p>
      );
    }
  }

  useEffect(async () => {
    (async () => {
      const response = await axios.get(
        "https://nft-parket-place-app.herokuapp.com/api/vouchers/getOwnerAddress"
      );
      dispatch(nftActions.setAllProfiles(response));
    })();
    (async () => {
      const AllNft = await axios.get(
        "https://nft-marketplace.adey-meselesh.de/api/vouchers/getAllVouchers/"
      );
      dispatch(nftActions.setAllNft(AllNft));
    })();
    const { address, status } = await getCurrentWalletConnected();
    console.log("inside wallet.js");
    setWallet(address);
    dispatch(walletActions.setWalletAddress(address));

    // setStatus(status);
    addWalletListener();
  }, []);
  return <Layout />;
}

export default App;
