import React from "react";
import Explore from "../../../pages/Explore";
import "./Home.css";
const Collection = () => {
  return (
    <div>
      <Explore />
    </div>
  );
};

export default Collection;
