import React from "react";
import "./FixedPrice.css";
import "./FixedPrice.scss";
import Controls from "../../../components/control/controls";
import { useDispatch, useSelector } from "react-redux";
import { imageSlice } from "../../../Slices/Image";

function FixedPrice() {
  const dispatch = useDispatch();
  const imageDetail = useSelector((state) => state.image.imageDetail);
  const offer = imageDetail.fixed.makeoffer;
  const imageActions = imageSlice.actions;

  return (
    <>
      {" "}
      <div className="fixed-price">
        <h2 className="head">Buy Now Price *</h2>
        <div className="price-information">
          <label htmlFor="">Price</label>
          <Controls.InputK
            onChange={(e) => {
              dispatch(imageActions.setPrice(e.target.value));
            }}
            className="price-input"
            name="name"
            placeholder="Price (ETH)"
          />
        </div>
        <div className="make-offer">
  <label class="container">
    <input type="checkbox" checked={imageDetail.fixed.makeoffer}
              onChange={(e) => {
                dispatch(imageActions.setOffer(e.target.checked));
              }}></input>
  <span class="checkmark"></span>
  </label>
  <h6 className="makeoffer-heading">Make Offer</h6>
  </div>

        {offer && (
          <div className="ending_date">
            <label htmlFor="">Ending Date</label>
            <input
              onChange={(e) => {
                dispatch(imageActions.setEndingdate(e.target.value));
              }}
              type="date"
            />
          </div>
        )}
      </div>
    </>
  );
}

export default FixedPrice;
