import React from "react";
import {
  createTheme,
  withStyles,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";

import { ConnectWallet } from "../../utils/Metamask/ConnectWallet";

import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import Button from "@material-ui/core/Button";
import { green, purple } from "@material-ui/core/colors";
import { walletSlice } from "../../Slices/Wallet";
import { pinJSONToIPFS } from "../../Json/Pinata/pinata";

const axios = require("axios");
const { ethers } = require("ethers");

const theme = createTheme({
  palette: {
    primary: green,
  },
});

const useStyles = makeStyles((theme) => ({
  margin: {
    marginTop: theme.spacing(1),
  },
  style: {
    marginBottom: theme.spacing(2),
  },
  buttonstyle: {
    marginTop: theme.spacing(3),
    height: theme.spacing(5),
    width: theme.spacing(15),
  },
}));

function CreateNft() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const walletActions = walletSlice.actions;
  const imageDetail = useSelector((state) => state.image.imageDetail);

  const CreateNFT = async () => {
    console.log("Image Detail" + JSON.stringify(imageDetail));
    const id = imageDetail.title;
    console.log("Image Image Url" + imageDetail.imageUrl);
    console.log("Image Description" + imageDetail.description);
    console.log("Image externalink" + imageDetail.externallink);
    console.log("Image category" + imageDetail.category);
    console.log("Image Price" + imageDetail.fixed.price);
    console.log("Image offer" + imageDetail.fixed.makeoffer);

    const walletResponse = await ConnectWallet();
    dispatch(walletActions.setWalletAddress(walletResponse.address));
    const owner = walletResponse.address;
    const price = imageDetail.fixed.price;
    const assetLink = imageDetail.imageUrl;
    const metadata = new Object();
    metadata.name = imageDetail.name;
    metadata.image = imageDetail.imageUrl;
    metadata.description = imageDetail.description;

    //make pinata call
    const pinataResponse = await pinJSONToIPFS(metadata);
    console.log("PinataResponse" + pinataResponse.success);
    if (!pinataResponse.success) {
      return {
        success: false,
        status: "😢 Something went wrong while uploading your tokenURI.",
      };
    }
    const tokenURI = pinataResponse.pinataUrl;
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const SIGNING_DOMAIN = "Sign Your NFT";
    const SIGNATURE_VERSION = "1";
    const tokenId = Date.now() + Math.floor(Math.random() * 100);
    const uri = tokenURI;
    const minPrice = imageDetail.fixed.price * 1000000000;
    const chainId = 3;
    const categorytype = imageDetail.category;

    /////////////////// Voucher ////////////////

    const voucher = { tokenId, uri, minPrice };
    const domain = {
      name: SIGNING_DOMAIN,
      version: SIGNATURE_VERSION,
      verifyingContract: "0x0eDfe998a3F0403b010EEd426AdACc390174B835",
      chainId,
    };
    const types = {
      NFTVoucher: [
        { name: "tokenId", type: "uint256" },
        { name: "minPrice", type: "uint256" },
        { name: "uri", type: "string" },
      ],
    };

    const signature = await signer._signTypedData(domain, types, voucher);
    const auctionLength =
      imageDetail.auction.auctionlength.days +
      `days` +
      imageDetail.auction.auctionlength.hours +
      `hours`;
    console.log("Auction Length" + auctionLength);
    //prepare,upload and store the redeemVoucher on db
    const redeemVoucher = {
      title: imageDetail.title,
      tokenId: tokenId,
      tokenUrl: tokenURI,
      signature: signature,
      signerAddress: await signer.getAddress(),
      assetLink: imageDetail.imageUrl,
      owner: await signer.getAddress(),
      typeofcategory: imageDetail.category,
      description: imageDetail.description,
      minted: false,
      typeOfSale: imageDetail.typeofsale,
      fixedPrice: {
        buyNowPrice: minPrice,
        saleEnds: imageDetail.fixed.endingdate,
        offer: imageDetail.fixed.makeoffer,
      },
      auction: {
        startingBidPrice: imageDetail.auction.startingbid,
        saleEnds: imageDetail.auction.endingdateauction,
      },
    };

    const data = JSON.stringify(redeemVoucher);
    console.log("Data to be send to database" + data);
    // console.log("All", redeemVoucher, data);

    try {
      const response = await axios.post(
        "https://nft-marketplace.adey-meselesh.de/api/vouchers/createVouchers",
        redeemVoucher
      );
      console.log("From CreateAllLVochers2022", response.data);
      // if (response) {
      //   console.log(response);
      // }
    } catch (error) {
      console.log("error :" + error);
    }
  };

  return (
    <button
      onClick={CreateNFT}
      className=" create__btn d-flex align-items-center gap-2"
    >
      <i class="ri-ball-pen-line"></i>
      <Link to={`/createdNftDetail`}>Create</Link>
    </button>
  );
}

export default CreateNft;
