const key = process.env.REACT_APP_PINATA_KEY;
const secret = process.env.REACT_APP_PINATA_SECRET;

const FormData = require("form-data");

const axios = require("axios");

//Pin file
export const pinFileToIPFS = async (myfile) => {
  console.log("my fileeee  :" + myfile);
  const url = `https://api.pinata.cloud/pinning/pinFileToIPFS`;
  let data = new FormData();
  //Accepting uploaded file
  //data.append('file', fs.createReadStream(myfile));
  //Adding metadata to it
  const metadata = JSON.stringify({
    name: "Kinfetestname",
    keyvalues: {
      exampleKey: "exampleValue",
    },
  });
  data.append("pinataMetadata", metadata);
  //Specifying the pin policy and add to the file
  const pinataOptions = JSON.stringify({
    cidVersion: 0,
    customPinPolicy: {
      regions: [
        {
          id: "FRA1",
          desiredReplicationCount: 1,
        },
        {
          id: "NYC1",
          desiredReplicationCount: 2,
        },
      ],
    },
  });
  data.append("pinataOptions", pinataOptions);

  //making axios POST request to Pinata ⬇️
  return axios
    .post(url, data, {
      maxBodyLength: "Infinity", //this is needed to prevent axios from erroring out with large files
      headers: {
        "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
        pinata_api_key: "5de47c87e59e393ad53e",
        pinata_secret_api_key:
          "f6dc959e8878181ca3354e9626a93d92d16e3e5ce20cb726c7ad18cea48e87fa",
      },
    })
    .then(function (response) {
      return {
        success: true,
        pinataUrl:
          "https://gateway.pinata.cloud/ipfs/" + response.data.IpfsHash,
      };
    })
    .catch(function (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    });
};

// Pin JSON data of file
export const pinJSONToIPFS = async (JSONBody) => {
  const url = `https://api.pinata.cloud/pinning/pinJSONToIPFS`;
  //making axios POST request to Pinata ⬇️
  return axios
    .post(url, JSONBody, {
      headers: {
        pinata_api_key: "5de47c87e59e393ad53e",
        pinata_secret_api_key:
          "f6dc959e8878181ca3354e9626a93d92d16e3e5ce20cb726c7ad18cea48e87fa",
      },
    })
    .then(function (response) {
      return {
        success: true,
        pinataUrl:
          "https://gateway.pinata.cloud/ipfs/" + response.data.IpfsHash,
      };
    })
    .catch(function (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    });
};
