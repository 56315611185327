import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";

import App from "./App";
import "bootstrap/dist/css/bootstrap.css";
import "remixicon/fonts/remixicon.css";
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import { walletSlice } from "./Slices/Wallet";
import { imageSlice } from "./Slices/Image";
import connetmodalReducer from "./Slices/ConnetModal";
import { nftSlice } from "./Slices/Nft";
import { categorySlice } from "./Slices/Category";
import { exploreSlice } from "./Slices/ExploreClicked";
const store = configureStore({
  reducer: {
    nft: nftSlice.reducer,
    wallet: walletSlice.reducer,
    connetmodal: connetmodalReducer,
    image: imageSlice.reducer,
    category: categorySlice.reducer,
    explorebutton: exploreSlice.reducer,
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Provider store={store}>
        <App />
      </Provider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
