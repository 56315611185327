import InputK from "./InputK";
import TextArea from "./TextArea";
import Button from './Button';
const Controls = {
    InputK,
    TextArea,
    Button

};

export default Controls;