import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./nft-card.css";
import { FaEthereum } from "react-icons/fa";

import Modal from "../Modal/Modal";

const NftCard = (props) => {
  const { title, id, tokenPrice, creatorImg, assetLink, owner } = props.item;
  console.log("Data inside Nft Card", props);
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="single__nft__card">
      <div className="nft__img">
        <img src={assetLink} alt="" className="w-100" />
      </div>

      <div className="nft__content">
        <h5 className="nft__title">
          <Link to={`/market/${id}`}>{title}</Link>
        </h5>

        <div className="creator__info-wrapper d-flex gap-3">
          {/* <div className="creator__img">
            <img src={creatorImg} alt="" className="w-100" />
          </div> */}

          <div className="creator__info w-100 d-flex align-items-center justify-content-between">
            <div className="nft__owner">
              <h6>CreatedBy</h6>
              <Link to="/dashboard">
                <p>
                  {String(owner).substring(0, 2) +
                    "..." +
                    String(owner).substring(38)}
                </p>
              </Link>
            </div>

            <div className="nft__price">
              <h6>Current Bid</h6>

              <p>
                <FaEthereum />
                {tokenPrice / 1000000} ETH
              </p>
            </div>
          </div>
        </div>

        {/* <div className=" mt-3 d-flex align-items-center justify-content-between">
          <button
            className="bid__btn d-flex align-items-center gap-1"
            onClick={() => setShowModal(true)}
          >
            <i class="ri-shopping-bag-line"></i> Place Bid
          </button>

          {showModal && <Modal setShowModal={setShowModal} />}

          <span className="history__link">
            <Link to="#">View History</Link>
          </span>
        </div> */}
      </div>
    </div>
  );
};

export default NftCard;
