import React, { useContext } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import "./Control.scss";
export default function InputK(props) {
  const { name, placeholder, value, onChange } = props;
  return (
    <div className="input-box">
      <input
        value={value}
        name={name}
        placeholder={placeholder}
        plac
        label={placeholder}
        onChange={onChange}
        type="text"
        onFocus="setFocus(true)"
        onBlur="setFocus(false)"
      />
    </div>
  );
}
