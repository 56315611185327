import { createSlice } from "@reduxjs/toolkit";
const initialStateValue = false;

export const exploreSlice = createSlice({
  name: "exploreclick",
  initialState: { value: initialStateValue },

  reducers: {
    setExploreClicked: (state, action) => {
      state.value = action.payload;
      console.log("inside Explore Clicked" + state.value);
    },
  },
});
