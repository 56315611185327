import { createSlice } from "@reduxjs/toolkit";
const initialStateValue = false;

export const connectmodalSlice = createSlice({
    name:'connectmodal',
    initialState:{value:initialStateValue},

    reducers: {
        setConnectModalClicked:(state,action) => {
            state.value = action.payload;
            console.log("inside Connect Modal"+state.value);
        },}
});
export const {setConnectModalClicked}=  connectmodalSlice.actions;

export default connectmodalSlice.reducer;
