import React from "react";
import { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import "./catagory.css";
import { categorySlice } from "./CatagorySlice";
import { useSelector, useDispatch } from "react-redux";
function Category() {
  const categoryActions = categorySlice.actions;
  const Allcatagory = useSelector((state) => state.category.AllCategory);
  const dispatch = useDispatch();
  useEffect(() => {
    (async () => {
      const Catagory = await axios.get(
        "https://nft-parket-place-app.herokuapp.com/api/vouchers/getListOfCategory"
      );

      const listCatagory = Catagory.data.listOfCategory;
      dispatch(categoryActions.setAllCategory(Catagory));
      console.log("Inside Category Component" + listCatagory);
    })();
  }, []);

  return (
    <div>
      {Allcatagory.map((catagory) => (
        <Link
          to="/FetchByCategory"
          className="catagory_button"
          onClick={() => dispatch(categoryActions.setCategoryType(catagory))}
          key={catagory}
          sx={{ my: 4, mx: 4, display: "inline" }}
          variant="h2"
        >
          {catagory}
        </Link>
      ))}
    </div>
  );
}

export default Category;
