import axios from "axios";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { useState, useContext } from "react";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import "./create.scss";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Profile from "./Profile";
import Graph from "./Graph";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaEthereum, FaListUl } from "react-icons/fa";
import { BsFillTagFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { MdTimeline } from "react-icons/md";
import { RiArrowUpDownFill } from "react-icons/ri";
import { CgMenuGridR } from "react-icons/cg";
import CardHeader from "@mui/material/CardHeader";
import { CardMedia } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import { blue, red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useSelector, useDispatch } from "react-redux";
import ShareIcon from "@mui/icons-material/Share";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { BuyNft } from "../../utils/Nft/ButNft";
console.log(FontAwesomeIcon);
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function createData(price, USDPrice, FloorDifference, Expiration, From) {
  return { price, USDPrice, FloorDifference, Expiration, From };
}

const rows = [
  createData("0.1 WETH", "$301.20", "71% below", "1 day", "yasa112233"),
  createData("0.1 WETH", "$301.20", "71% below", "1 day", "yasa112233"),
  createData("0.1 WETH", "$301.20", "71% below", "1 day", "yasa112233"),
];

export default function Create(props) {
  const dispatch = useDispatch();
  const NftData = useSelector((state) => state.nft.NftData);
  console.log("inside Create function" + JSON.stringify(NftData));
  console.log("Image Price" + NftData.tokenPrice);
  console.log("Image owner" + NftData.owner);
  console.log("Image imgurl" + NftData.assetLink);

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [age, setAge] = useState("");

  const handleSelectChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <div className="main">
      <Box sx={{ flexGrow: 1, borderRadius: 16 }}>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <div className="left-side">
              <Profile linkToImage={NftData.assetLink} />
            </div>
          </Grid>
          <Grid item xs={7}>
            <a
              href="https://www.pinterest.com/hillarytesfay/_saved/"
              target="_blank"
            >
              <p>Hilary & Yibrah </p>
            </a>
            <div>
              <h2>NFT Marketplace</h2>
            </div>
            <div>
              <h6>
                owned by <Link to="/dashboard">{NftData.owner}</Link>
              </h6>
            </div>
            <Card sx={{ minWidth: 275 }}>
              <CardContent>
                <Typography
                  sx={{ fontSize: 18 }}
                  color="text.secondary"
                  gutterBottom
                >
                  Current price
                </Typography>
                <Typography variant="h4" component="div">
                  <FaEthereum /> {NftData.tokenPrice / 1000000000}
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  size="large"
                  variant="outlined"
                  style={{ width: 300, height: 50, borderRadius: 15 }}
                >
                  <BsFillTagFill />
                  Make offer
                </Button>
                <Button
                  size="large"
                  variant="outlined"
                  style={{ width: 300, height: 50, borderRadius: 15 }}
                  onClick={() => BuyNft(NftData)}
                >
                  Buy
                </Button>
              </CardActions>
            </Card>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography sx={{ width: "33%", flexShrink: 0 }} variant="h6">
                  <MdTimeline /> Price History
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Card sx={{ minWidth: 275 }}>
                  <CardContent>
                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                      <Select
                        value={age}
                        onChange={handleSelectChange}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem value="">
                          <em>All Time</em>
                        </MenuItem>
                        <MenuItem value={7}>Last 7 days</MenuItem>
                        <MenuItem value={9}>Last 9 days</MenuItem>
                        <MenuItem value={14}>last 14 days</MenuItem>
                      </Select>
                    </FormControl>
                  </CardContent>
                </Card>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionDetails>
                <img src="empty_image.png" alt="empty" />
                <div>No listing here</div>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel3bh-header"
              >
                <Typography sx={{ width: "33%", flexShrink: 0 }} variant="h5">
                  <FaListUl /> offers
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="right">price</TableCell>
                        <TableCell align="right">USD Price</TableCell>
                        <TableCell align="right">Floor Differance</TableCell>
                        <TableCell align="right">Exparation</TableCell>
                        <TableCell align="right">From</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow
                          key={row.name}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {row.name}
                          </TableCell>
                          <TableCell align="right">{row.price}</TableCell>
                          <TableCell align="right">{row.USDPrice}</TableCell>
                          <TableCell align="right">
                            {row.FloorDifference}
                          </TableCell>
                          <TableCell align="right">{row.Expiration}</TableCell>
                          <TableCell align="right">{row.From}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography sx={{ width: "33%", flexShrink: 0 }} variant="h5">
              <RiArrowUpDownFill /> Item Activity
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                value={age}
                onChange={handleSelectChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="">
                  <em>All Time</em>
                </MenuItem>
                <MenuItem value={7}>Last 7 days</MenuItem>
                <MenuItem value={9}>Last 9 days</MenuItem>
                <MenuItem value={14}>last 14 days</MenuItem>
              </Select>
            </FormControl>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="right">price</TableCell>
                    <TableCell align="right">USD Price</TableCell>
                    <TableCell align="right">Floor Differance</TableCell>
                    <TableCell align="right">Exparation</TableCell>
                    <TableCell align="right">From</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="right">{row.price}</TableCell>
                      <TableCell align="right">{row.USDPrice}</TableCell>
                      <TableCell align="right">{row.FloorDifference}</TableCell>
                      <TableCell align="right">{row.Expiration}</TableCell>
                      <TableCell align="right">{row.From}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            <Typography variant="h5">
              <CgMenuGridR />
              More From This collection
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CardHeader
              avatar={
                <Avatar sx={{ bgcolor: blue[500] }} aria-label="recipe">
                  H
                </Avatar>
              }
              action={
                <IconButton aria-label="settings">
                  <MoreVertIcon />
                </IconButton>
              }
              title="birds image"
              subheader="April 03, 2022"
            />
            <CardMedia
              component="img"
              height="194"
              width="10"
              image="bird.jpg"
              alt="birds image"
            />

            <CardContent>
              <Typography variant="body2" color="text.secondary">
                This beautiful two birds are a symbol of love and peace,in
                abeautiful nature that is pleasent to the eye and an ease for
                the heart.
              </Typography>
            </CardContent>
            <CardActions disableSpacing>
              <IconButton aria-label="add to favorites">
                <FavoriteIcon />
              </IconButton>
              <IconButton aria-label="share">
                <ShareIcon />
              </IconButton>

              <ExpandMoreIcon />
            </CardActions>
          </AccordionDetails>
        </Accordion>
      </Box>
    </div>
  );
}
