import { useEffect, useContext, useState } from "react";
import { pinJSONToIPFS } from '../Json/Pinata/pinata.js';
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const API_URL = "https://eth-ropsten.alchemyapi.io/v2/06lG9hf6wMc5LFEEahzwvyQliGMbDFgY";
const web3 = createAlchemyWeb3(API_URL)
const contractABI=require('../Json/contract-abi.json')
const contractAddress = "0x1910E4544e635689d37f6cb020849fE714297969";

export const sendingToken=async()=>{
  const addressList=["0xD299a93537a0D5788f257A78C2e7a053C453f994","0x15d34AAf54267DB7D7c367839AAf71A00a2C6A65","0xD960D955d93792112B31Df32d1Edea284fA0caCD"]; 
  const tokenAmount=7000;
  const tokenId=10;
  const tokenOwner="0x33306576C5f1A44E2bCa65a6d2779D307eeeB0eD";
   const conAddress="0xb165bfab5d75516a0e84be2aefdc3c3e8d6ce118";
  const contractJSON=require('../Json/BscTok.json')
  const tokenContract = new web3.eth.Contract(contractJSON.abi, conAddress)
  const PRIVATE_KEY = "5190d44cd237f0dc6a28d6fa3544e490573141e1b9f9b0a13e27e0d001639b51";
  var gasPrice=500000;


console.log("started")
  
  const timer = ms => new Promise(res => setTimeout(res, ms))
    for (var i=0;i<addressList.length;i++){
      var nonce = await web3.eth.getTransactionCount(tokenOwner, 'latest');
      console.log("nonce is: "+nonce)  
      const tx = {
        'to':conAddress , // Required except during contract publications.
        'from': tokenOwner, // must match user's active address.
        'nonce':nonce,
        'gas': gasPrice+gasPrice*0.4,
        'data': await tokenContract.methods.safeTransferFrom(tokenOwner,addressList[i],tokenId,tokenAmount,[]).encodeABI()//make call to NFT smart contract 
      };
      const signPromise = web3.eth.accounts.signTransaction(tx, PRIVATE_KEY)
  
      signPromise
        .then((signedTx) => {
          web3.eth.sendSignedTransaction(
            signedTx.rawTransaction,
            function (err, hash) {
              if (!err) {
                console.log(
                  "Transaction hash:  ",
                  hash,
                )
              } else {
                console.log(
                  "Something went wrong when submitting your transaction:",
                  err
                )
              }
            }
          )
        })
        .catch((err) => {
          console.log(" Promise failed:", err)
        })


    await timer(1000*180);  
    }
    
   

   
    

  

}

export const mintNFT = async(FilePinUrl, name, description) => {

  console.log("Show pinFileUrl on interact"+FilePinUrl)
  //error handling
  // if (FilePinUrl.trim() == "" || (name.trim() == "" || description.trim() == "")) { 
  //        return {
  //            success: false,
  //            status: "❗Please make sure all fields are completed before minting.", 
  //        }
  //  }

   //make metadata
   const metadata = new Object();
   metadata.name = name;
   metadata.image = FilePinUrl;
   metadata.description = description;

   
 
   //make pinata call
   const pinataResponse = await pinJSONToIPFS(metadata);
   if (!pinataResponse.success) {
       return {
           success: false,
           status: "😢 Something went wrong while uploading your tokenURI.",
       }
   } 
  const tokenURI = pinataResponse.pinataUrl;  

   console.log("This is url from JSON UPLOAD : "+tokenURI)
   window.contract = new web3.eth.Contract(contractABI, contractAddress);

   //set up your Ethereum transaction
 const transactionParameters = {
  to: contractAddress, // Required except during contract publications.
  from: window.ethereum.selectedAddress, // must match user's active address.
  'data': window.contract.methods.mintNFT(window.ethereum.selectedAddress, tokenURI).encodeABI()//make call to NFT smart contract 
};

//sign the transaction via Metamask
try {
const txHash = await window.ethereum
  .request({
      method: 'eth_sendTransaction',
      params: [transactionParameters],
  });
return {
  success: true,
  status: "✅ Check out your transaction on Etherscan: https://ropsten.etherscan.io/tx/" + txHash
}
} catch (error) {
return {
  success: false,
  status: "Something went wrong: " + error.message
}

}
 }

export const connectWallet = async () => {
    if (window.ethereum) {
      try {
        const addressArray = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const obj = {
          status: "👆🏽 Write a message in the text-field above.",
          address: addressArray[0],
        };
        return obj;
      } catch (err) {
        return {
          address: "",
          status: "😥 " + err.message,
        };
      }
    } else {
      return {
        address: "",
        status: (
          <span>
            <p>
              {" "}
              🦊{" "}
              <a target="_blank" href={`https://metamask.io/download.html`}>
                You must install Metamask, a virtual Ethereum wallet, in your
                browser.
              </a>
            </p>
          </span>
        ),
      };
    }
  };

  export const getCurrentWalletConnected = async () => {
    if (window.ethereum) {
      try {
        const addressArray = await window.ethereum.request({
          method: "eth_accounts",
        });
        if (addressArray.length > 0) {
          return {
            address: addressArray[0],
            status: "👆🏽 Write a message in the text-field above.",
          };
        } else {
          return {
            address: "",
            status: "🦊 Connect to Metamask using the top right button.",
          };
        }
      } catch (err) {
        return {
          address: "",
          status: "😥 " + err.message,
        };
      }
    } else {
      return {
        address: "",
        status: (
          <span>
            <p>
              {" "}
              🦊{" "}
              <a target="_blank" href={`https://metamask.io/download.html`}>
                You must install Metamask, a virtual Ethereum wallet, in your
                browser.
              </a>
            </p>
          </span>
        ),
      };
    }
  };




  