import React, { useState } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import CreateNft from "../components/CreatNft/CreateNft";
// import "./hero-section.css";
import CommonSection from "../components/ui/Common-section/CommonSection";
import NftCard from "../components/ui/Nft-card/NftCard";
import img from "../assets/images/img-01.jpg";
import avatar from "../assets/images/ava-01.png";
import "../styles/create-item.css";
import FormData from "form-data";
import { useSelector, useDispatch } from "react-redux";
import { imageSlice } from "../Slices/Image";
import TypeSale from "../components/TypeSale/TypeSale";

import "../components/ui/Nft-card/nft-card.css";

// const key = process.env.REACT_APP_PINATA_KEY;
// const secret = process.env.REACT_APP_PINATA_SECRET;
const key = "5de47c87e59e393ad53e";
const secret =
  "f6dc959e8878181ca3354e9626a93d92d16e3e5ce20cb726c7ad18cea48e87fa";
const axios = require("axios");

const item = {
  id: "01",
  title: "Guard",
  desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veniam adipisci cupiditate officia, nostrum et deleniti vero corrupti facilis minima laborum nesciunt nulla error natus saepe illum quasi ratione suscipit tempore dolores. Recusandae, similique modi voluptates dolore repellat eum earum sint.",
  imgUrl: img,
  creator: "Trista Francis",
  creatorImg: avatar,
  currentBid: 7.89,
};
const Create = () => {
  const imageDetail = useSelector((state) => state.image.imageDetail);
  console.log("inside FileUpload" + imageDetail);
  const dispatch = useDispatch();
  const imageActions = imageSlice.actions;

  const statee = {
    imgPreview: " ",
  };
  const [file, setFile] = useState(statee);
  const [fileObject, setFileObject] = useState("");
  var FilePinUrl = "";

  const onFileChange = async (e) => {
    //Image preview to help client choose the correct file before upload
    setFile({
      imgPreview: e.target.files[0],
    });
    setFileObject(e.target.files[0]);
    const fr = new FileReader();
    fr.readAsDataURL(e.target.files[0]);
    fr.onloadend = function (e) {
      setFile({ imgPreview: fr.result });
    };

    console.log("Image Upload :" + file.name);
  };

  console.log("Image Upload :" + file.name);
  const onFileUpload = async (e) => {
    console.log("image Detail" + imageDetail);
    e.preventDefault();
    const apiUrl = `https://api.pinata.cloud/pinning/pinFileToIPFS`;
    let data = new FormData();
    data.append("file", fileObject);
    //adding metada to the asset
    const metadata = JSON.stringify({
      name: "yibrah",
      catagory: "music",
      price: 10,
      keyvalues: {
        exampleKey: "exampleValue",
      },
    });
    data.append("pinataMetadata", metadata);
    axios
      .post(apiUrl, data, {
        maxBodyLength: "Infinity", //this is needed to prevent axios from erroring out with large files
        headers: {
          "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
          pinata_api_key: key,
          pinata_secret_api_key: secret,
        },
      })
      .then(function (res) {
        FilePinUrl = "https://gateway.pinata.cloud/ipfs/" + res.data.IpfsHash;
        console.log("imge Url pinata" + FilePinUrl);

        dispatch(imageActions.setImgUrl(FilePinUrl));
        console.log("image Url" + imageDetail.imageUrl);
        console.log("Asset pinned to :" + FilePinUrl);
        console.log("Image pinned successfully!!  " + res.data.IpfsHash);
      })
      .catch(function (error) {
        console.log("Pinning failed :" + error);
      });
  };
  return (
    <>
      <CommonSection title="Create New Item" />

      <div className="create-nft">
        <Row>
          <Col lg="3" md="4" sm="6">
            <h5 className="mb-4 text-light">Preview Item</h5>

            <div className="single__nft__card">
              <div className="nft__img">
                <img src={file.imgPreview} alt="" className="w-100" />
              </div>

              <div className="nft__content">
                <h5 className="nft__title">
                  <Link to={`/market/${item.id}`}>{imageDetail.title}</Link>
                </h5>

                <div className="creator__info-wrapper d-flex gap-3">
                  <div className="creator__img">
                    <img src={item.creatorImg} alt="" className="w-100" />
                  </div>

                  <div className="creator__info w-100 d-flex align-items-center justify-content-between">
                    <div>
                      <h6>Created By</h6>
                      <p>{item.creator}</p>
                    </div>

                    <div>
                      <h6>Current Bid</h6>
                      <p>{imageDetail.fixed.price} ETH</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <NftCard src={file.imgPreview} item={item} /> */}
          </Col>
          <Col lg="9" md="8" sm="6">
            <div className="create__item">
              <form
                onSubmit={(e) => {
                  onFileUpload(e);
                }}
              >
                <div className="wrapper">
                  <input
                    type="file"
                    onChange={(e) => {
                      onFileChange(e);
                    }}
                    className="upload__input"
                  />
                  <img className="image-preview" src={file.imgPreview}></img>

                  <button
                    onChange={(e) => {
                      onFileUpload(e);
                    }}
                    className="bid__btn upload-button d-flex align-items-center gap-1"
                  >
                    <i class="ri-shopping-bag-line"></i> Upload!
                  </button>
                </div>
                <div className="typeofsale">
                  <TypeSale />
                </div>

                {/* <div className=" d-flex align-items-center gap-4">
                    <div className="form__input w-50">
                      <label htmlFor="">Starting Date</label>
                      <input type="date" />
                    </div>

                    <div className="form__input w-50">
                      <label htmlFor="">Expiration Date</label>
                      <input type="date" />
                    </div>
                  </div> */}

                <div className="form__input">
                  <label htmlFor="">Collection</label>
                  <p>This is the collection where your item will appear.</p>

                  <select
                    onChange={(e) => {
                      dispatch(imageActions.setCategory(e.target.value));
                    }}
                    classname="category-list"
                    id="Category"
                    name="category"
                  >
                    <option>Trending</option>

                    <option>Top</option>
                    <option>Music</option>
                    <option>Arts</option>
                    <option>Games</option>
                    <option>Photography</option>
                    <option>Sports</option>
                  </select>
                </div>
                <div className="form__input">
                  <label htmlFor="">Blockchain</label>
                  <select className="category-list" id="country" name="country">
                    <option value="australia">Ethereum</option>
                    <option value="canada">Polygon</option>
                  </select>
                </div>
                <div className="form__input">
                  <label htmlFor="">Description</label>
                  <textarea
                    onChange={(e) => {
                      dispatch(imageActions.setDescription(e.target.value));
                    }}
                    name=""
                    id=""
                    rows="7"
                    placeholder="Enter description"
                    className="w-100"
                  ></textarea>
                </div>
                <CreateNft />
              </form>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Create;
