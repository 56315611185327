import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./createdNftDetail.scss";
import { Link } from "react-router-dom";
import { DETAILS_DATA } from "../../../src/assets/data/data";
import {
  Card,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Container,
  Row,
  Col,
} from "reactstrap";
import axios from "axios";

// "https://nft-marketplace.adey-meselesh.de/api/vouchers/getAllVoucherForSingleOwner/0xbdb6652955ade42b1995d26fb5a999f200137"
const CreatedNftDetail = () => {
  const dispatch = useDispatch();
  const walletAddress = useSelector((state) => state.wallet.value);
  const imageDetail = useSelector((state) => state.image.imageDetail);
  const [open, setOpen] = useState("");

  let [allOwnerNft, setAllOwnerNft] = useState([]);
  const toggle = (id) => {
    open === id ? setOpen() : setOpen(id);
  };
  // Fetch data from JSON files

  // let imageUrl = DETAILS_DATA[0].imageUrl;
  // let userId = DETAILS_DATA[0].ownerId;
  // let makeOffer = DETAILS_DATA[0].offor;
  // let allImage = DETAILS_DATA[0].allImageOwned;
  // let description = DETAILS_DATA[0].description;
  // console.log(allImage, "All imageeeeeeeeeeeeee");
  // console.log("Top", imageDetail);
  let nftTitle = imageDetail.title;
  let nftImagUrl = imageDetail.imageUrl;
  let nftDescription = imageDetail.description;
  let nftMakeOffer = imageDetail.fixed.makeoffer;
  let nftPrice = imageDetail.fixed.price;

  useEffect(() => {
    const getResults = async () => {
      const results = await axios.get(
        `https://nft-marketplace.adey-meselesh.de/api/vouchers/getAllVoucherForSingleOwner/0x43F5Ec8397F45F9F42526773688C7B39bD86c221`
      );
      setAllOwnerNft(results.data.data.Vouchers);
    };
    getResults();
  }, []);
  console.log("BackEnd result", allOwnerNft);

  return (
    <div className="createNftDetailContainer">
      <div className="fitstListContainer">
        <div>
          <Card className="cardImage">
            <img alt="" src={nftImagUrl} />
          </Card>
        </div>
        <div className="secondStepofCoantier">
          <div>
            <h5>Title {nftTitle}</h5>

            <div className="viewsContainer">
              <h5>Owned by</h5>
              <span>{walletAddress.substring(0, 10)}</span>
              {`Price ${nftPrice} ETH`}
            </div>
          </div>
          <div>
            <Card className="makeOfferContianer">
              <Button className="makeOfferbutton">
                {nftMakeOffer && (
                  <div>
                    <i class="ri-hand-heart-fill"></i>MakeOffer
                  </div>
                )}
              </Button>
            </Card>
          </div>
        </div>
      </div>
      <div className="sencondContianerOfCreta">
        <div className="firsListofSecond">
          <div className="hideList">
            <Accordion flush open={open} toggle={toggle} className="ac1">
              <div className="description">
                <AccordionItem>
                  <AccordionHeader targetId="2">
                    <i class="ri-sort-desc"></i>Descriptison
                  </AccordionHeader>
                  <AccordionBody accordionId="2">
                    {nftDescription}
                  </AccordionBody>
                </AccordionItem>
              </div>
            </Accordion>
          </div>
        </div>
        <div className="collectionList">
          <Card>
            <Accordion flush open={open} toggle={toggle}>
              <AccordionItem>
                <AccordionHeader targetId="1" className="accordionHeader">
                  <div>
                    <i class="ri-menu-unfold-fill"></i>More from this collection
                  </div>
                </AccordionHeader>
                <AccordionBody accordionId="1" className="accordingBody">
                  <Container>
                    <Row>
                      <Col lg="12" className="mb-5"></Col>
                      <Col>
                        <div className="createdNftDetailMarketPlaces">
                          {allOwnerNft.map((voucheres, index) => {
                            return (
                              <div
                                className="createdNftDetailMarketplaceplace"
                                key={index._id}
                              >
                                <div className="createdNftDetailImage">
                                  <img
                                    src={voucheres.assetLink}
                                    alt="marketplace"
                                  />
                                  <div></div>
                                </div>

                                <div class="createdNftDetailCard-image">
                                  <img
                                    src={voucheres.assetLink}
                                    alt=""
                                    class="createdNftDetailCard-img"
                                  />
                                </div>
                                <h6 className="createdNftDetailUsername">
                                  <span>Created by</span>
                                  <Link to="/createdNftDetail">
                                    <span className="createdNftDetailOwneraddress">
                                      {walletAddress.substring(0, 10)}
                                    </span>
                                  </Link>
                                </h6>
                              </div>
                            );
                          })}
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default CreatedNftDetail;
