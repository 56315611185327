import React from "react";
import Controls from "../../../components/control/controls";
import "../FixedPrice/FixedPrice.css";
import { useDispatch, useSelector } from "react-redux";
import { imageSlice } from "../../../Slices/Image";

import AuctionLength from "./AuctionLength/AuctionLength";

function FixedPrice() {
  const dispatch = useDispatch();
  const imageDetail = useSelector((state) => state.image.imageDetail);
  const imageActions = imageSlice.actions;

  return (
    <div className="fixed-price">
      <h2 className="head"> Auction</h2>
      <div className="price-information">
        <label htmlFor="">Starting Bid Price *</label>
        <Controls.InputK
          onChange={(e) => {
            dispatch(imageActions.setStartingbid(e.target.value));
          }}
          className="price-input"
          name="name"
          placeholder="Price (ETH)"
        />
      </div>
      <div >
      <div className="ending_date">
            <label htmlFor="">Ending Date</label>
            <input
             onChange={(e) => {
              dispatch(imageActions.setEndingdateauction(e.target.value));
            }}
             
              type="date"
            />
          </div>      </div>
    </div>
  );
}

export default FixedPrice;
