import React from "react";
import { TextField } from "@material-ui/core";
import Box from "@mui/material/Box";
import "./Control.scss";

export default function TextArea(props) {
  const { name, value, onChange, error, rows, style } = props;
  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 0, width: "86ch" },
      }}
      noValidate
      autoComplete="off"
    >
      <TextField
        variant="outlined"
        name={name}
        rows={rows}
        column={100}
        style={style}
        multiline
        value={value}
        onChange={onChange}
      />
    </Box>
  );
}
