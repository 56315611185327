import { createSlice } from "@reduxjs/toolkit";
const initialStateValue = "";

export const walletSlice = createSlice({
  name: "wallet",
  initialState: { value: initialStateValue },

  reducers: {
    setWalletAddress: (state, action) => {
      state.value = action.payload;
      console.log("Wallet Address" + state.value);
    },
  },
});
