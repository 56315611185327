import { React, useState } from "react";
import { AiOutlineFileImage } from "react-icons/ai";
import "./ProfileImage.css";
import CommonSection from "../../ui/Common-section/CommonSection";

function Setting() {
  const [profile, setProfile] = useState(
    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
  );
  return (
    <div>
      <CommonSection title={"MarketPlace"} />
      <img src={profile} alt="profile image" id="img" className="img" />
      <h4 className="text">Unknown</h4>
      <input type="file" name="image-upload" id="input" accept="image/*" />
      <label htmlFor="input">
        <AiOutlineFileImage />
      </label>
    </div>
  );
}

export default Setting;
