import React, { useState } from "react";
import styled from "styled-components";
import FixedPrice from "./FixedPrice/FixedPrice";
import Auction from "./Auction/Auction";
import { imageSlice } from "../../Slices/Image";

import { useDispatch, useSelector } from "react-redux";
import "./TypeSale.css";
const axios = require("axios");
const Button = styled.button`
border-radius: 4rem;
padding: 0.8rem 2rem;
margin:1rem;
border: none;
color: white;
font-size: 1.1rem;
cursor: pointer;
background-color: transparent;
border: 1px solid white;
  }
`;
const ButtonToggle = styled(Button)`
  opacity: 0.6;
  ${({ active }) =>
    active &&
    `
    background-color: #2d69fd;
  `}
`;
const ButtonGroup = styled.div`
  display: flex;
`;

const Section = styled.section`
  display: flex;
  width:100%;

  flex-direction: column;
  
  .marketPlaceTypes {
    display: flex;
    width:100%;
    button:not(.blue) {
      color: white;
      border-color: #7b7e86;
    }
  }


`;
const typeOfSale = ["Fixed Price", "Auction"];
function TypeSale() {
  const dispatch = useDispatch();
  const imageActions = imageSlice.actions;
  const [fixed, setFixed] = useState(true);
  const [active, setActive] = useState(typeOfSale[0]);
  const [auction, setAuction] = useState(false);
  const typeSaleClickHandler = async (index) => {
    console.log(index.target.innerHTML);
    if (index.target.innerHTML == typeOfSale[0]) {
      setActive(typeOfSale[0]);
      dispatch(imageActions.setTypeofsale(typeOfSale[0]));
      setAuction(false);
      setFixed(true);
    } else {
      setActive(typeOfSale[1]);
      dispatch(imageActions.setTypeofsale(typeOfSale[1]));
      setFixed(false);
      setAuction(true);
    }
  };
  return (
    <div className="form__input">
      <label htmlFor="">Title</label>
      <input
        onChange={(e) => {
          dispatch(imageActions.setTitle(e.target.value));
        }}
        type="text"
        placeholder="Enter title"
      />
      <Section>
        <div className="marketPlaceTypes">
          <div>
            <label className="sale-head" htmlFor="">
              Type Of Sale
            </label>
            <ButtonGroup>
              {typeOfSale.map((type, index) => (
                <ButtonToggle
                  key={type}
                  value={index}
                  active={active === type}
                  onClick={(value) => {
                    typeSaleClickHandler(value);
                  }}
                >
                  {type}
                </ButtonToggle>
              ))}
            </ButtonGroup>
          </div>
        </div>
        <div className="type-sale">
          {fixed && <FixedPrice />}
          {auction && <Auction />}
        </div>
      </Section>
    </div>
  );
}

export default TypeSale;
