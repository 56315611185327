import axios from 'axios';

const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const API_URL = "https://eth-ropsten.alchemyapi.io/v2/06lG9hf6wMc5LFEEahzwvyQliGMbDFgY";
const web3 = createAlchemyWeb3(API_URL);
const contractABI=require("../Json/Lazymint.json");

const { ethers } = require("ethers");

export const redeemNFT = async (data) => {
  console.log("inside redeem finction ::" + data);
  const contractAddress = "0x0eDfe998a3F0403b010EEd426AdACc390174B835";
  const nftContract = new web3.eth.Contract(contractABI, contractAddress);
  console.log("check data minted:" + data.minted)
  const voucherData = [data.tokenId, data.tokenPrice, data.tokenUri, data.signature]
  console.log(data.tokenId + " " + data.tokenPrice + " " + data.tokenUri + " " + data.signature)
  //creating the transaction parametrs for MINT and Transfer NFT
  try {
    //capturing buyers address from metamask
    const buyerAddress = window.ethereum.selectedAddress
    console.log("buyer address: " + buyerAddress)
    //converting value to blockchan readable format
    const amount = web3.utils.toWei(data.tokenPrice, 'gwei');
    const value = web3.utils.toHex(amount);
    const txParam = {
      from: buyerAddress,
      to: contractAddress,
      value: value,
      data: (data.minted ? await nftContract.methods.transferRedeem(buyerAddress, data.owner, data.tokenId).encodeABI() : await nftContract.methods.redeem(buyerAddress, voucherData).encodeABI()), //make call to NFT smart contract
    };

    try {
      const txHash = await window.ethereum
        .request({
          method: 'eth_sendTransaction',
          params: [txParam],
        });
      return {
        success: true,
        status: "Check out your transaction on Etherscan: https://ropsten.etherscan.io/tx/" + txHash
      }
    } catch (error) {

      return {
        success: false,
        status: "Something went wrong: " + error.message
      }

    }
  } catch (error) {
    console.log("Error occured during minting tx :" + error)
  }
}