import React, { useState, useEffect } from "react";
import CommonSection from "../components/ui/Common-section/CommonSection";
// import { NFT__DATA } from "../assets/data/data";
import axios from "axios";
import { Container, Row, Col } from "reactstrap";
import { nftSlice } from "../Slices/Nft";
import "../styles/market.scss";
import { useSelector, useDispatch } from "react-redux";
import Category from "../components/ui/Catagory/Catagory";
import { BsThreeDots } from "react-icons/bs";
import { Link } from "react-router-dom";
import styled from "styled-components";
import marketplace3 from "../assets/marketplace/marketplace3.png";
import art1 from "../assets/marketplace/art1.jpg";
import art3 from "../assets/marketplace/art3.jpg";

const Market = () => {
  // const nftActions = nftSlice.actions;
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   (async () => {
  //     const response = await axios.get(
  //       "https://nft-parket-place-app.herokuapp.com/api/vouchers/getOwnerAddress"
  //     );
  //     dispatch(nftActions.setAllProfiles(response));
  //   })();
  // }, []);
  const ExploreAllNft = useSelector((state) => state.nft.AllProfiles);
  console.log("All Addresses Inside Market component" + ExploreAllNft);
  const [data, setData] = useState(null);
  const handleItems = () => {};
  // ====== SORTING DATA BY HIGH, MID, LOW RATE =========
  const handleSort = (e) => {
    const filterValue = e.target.value;

    if (filterValue === "high") {
      const filterData = ExploreAllNft.filter((item) => item.currentBid >= 6);

      setData(filterData);
    }

    if (filterValue === "mid") {
      const filterData = ExploreAllNft.filter(
        (item) => item.currentBid >= 5.5 && item.currentBid < 6
      );

      setData(filterData);
    }

    if (filterValue === "low") {
      const filterData = ExploreAllNft.filter(
        (item) => item.currentBid >= 4.89 && item.currentBid < 5.5
      );

      setData(filterData);
    }
  };

  return (
    <>
      <CommonSection title={"MarketPlace"} />

      <section>
        <Container>
          <Row>
            <Col lg="12" className="mb-5"></Col>
            <Col>
              <div className="marketPlaces">
                {ExploreAllNft.map((profile, index) => {
                  return (
                    <div className="marketplace">
                      <div className="image">
                        <img src={art1} alt="marketplace" />
                        <div></div>
                      </div>

                      <div class="card-image">
                        <img src={art1} alt="" class="card-img" />
                      </div>

                      <div className="name">
                        <h5>Nft Profile</h5>
                        <BsThreeDots />
                      </div>
                      <h6 className="username">
                        <span>created by</span>{" "}
                        <Link to="/dashboard">
                          <span className="owneraddress">
                            {profile.substring(0, 10)}
                          </span>
                        </Link>
                      </h6>
                    </div>
                  );
                })}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Market;
