import { Route, Routes } from "react-router-dom";
import Lists from "../../profile/list/List";
import "./dashboard.css";
import Order from "../../profile/order/Order";
import Review from "../../profile/review/Review";
import Earning from "../../profile/earning/Earning";
import LeftSide from "../sidebar/LeftSide";
import Collection from "../home/Collection";
import CommonSection from "../../ui/Common-section/CommonSection";
import ProfileImage from "./ProfileImage";

function Dashboard(props) {
  return (
    <div>
      <CommonSection title={"MarketPlace"} />
      <ProfileImage />
      <div className="home">
        <LeftSide />
        <div className="homeContainer">
          <Routes>
            <Route path="/" element={<Collection />}></Route>
            <Route path="/store" element={<Lists />}></Route>
            <Route path="/order" index element={<Order />} />
            <Route path="/review" index element={<Review />} />
            <Route path="/earning" index element={<Earning />} />{" "}
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
