import React, { useState } from "react";
import { AiOutlineFileImage } from "react-icons/ai";
import "./ProfileImage.css";
import { GrEdit } from "react-icons/gr";
import { Link } from "react-router-dom";

function ProfileImage() {
  const [profile, setProfile] = useState(
    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
  );

  return (
    <div>
      <img src={profile} alt="profile image" id="img" className="img" />
      <h4 className="text">
        Unknown{" "}
        <Link to="/setting" className="edit">
          <GrEdit />
        </Link>
      </h4>
    </div>
  );
}

export default ProfileImage;
