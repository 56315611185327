import { React, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import axios from "axios";
import { nftSlice } from "../../../Slices/Nft";

// import { NFT__DATA } from "../../../assets/data/data";
import "./trending.css";

import NftCard from "../Nft-card/NftCard";

const Trending = () => {
  // const nftActions = nftSlice.actions;
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   (async () => {
  //     const response = await axios.get(
  //       "https://nft-parket-place-app.herokuapp.com/api/vouchers/getAllVouchers"
  //     );
  //     dispatch(nftActions.setAllProfiles(response));
  //   })();
  // }, []);
  const AllNft = useSelector((state) => state.nft.AllNft);
  const AllTrending = AllNft.filter((nft) => nft.typeofcategory == "Trending");
  console.log("Trending" + AllTrending);
  return (
    <section>
      <Container>
        <Row>
          <Col lg="12" className="mb-5">
            <h3 className="trending__title">Trending</h3>
          </Col>

          {AllTrending.map((item) => (
            <Col lg="3" md="4" sm="6" className="mb-4">
              <NftCard key={item.id} item={item} />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Trending;
