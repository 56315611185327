import React, { useState } from "react";

import CommonSection from "../components/ui/Common-section/CommonSection";

import NftCard from "../components/ui/Nft-card/NftCard";
import { useEffect } from "react";
// import { NFT__DATA } from "../assets/data/data";
import axios from "axios";
import { Container, Row, Col } from "reactstrap";
import { nftSlice } from "../Slices/Nft";
// import "../styles/market.css";
import { categorySlice } from "../components/ui/Catagory/CatagorySlice";
import { useSelector, useDispatch } from "react-redux";
import Category from "../components/ui/Catagory/Catagory";

const Explore = () => {
  const nftActions = nftSlice.actions;
  const dispatch = useDispatch();

  const [data, setData] = useState(null);
  useEffect(() => {
    (async () => {
      const response = await axios.get(
        "https://nft-marketplace.adey-meselesh.de/api/vouchers/getAllVouchers/"
      );
      console.log("reesssssssssponseeeeee", response);
      setData(response.data.data.Vouchers);

      dispatch(nftActions.setAllProfiles(response));
    })();
  }, []);
  const ExploreAllNft = useSelector((state) => state.nft.AllProfiles);
  console.log("Inside Profile Component using useSelector" + ExploreAllNft);

  const handleCategory = () => {};

  const handleItems = () => {};

  // ====== SORTING DATA BY HIGH, MID, LOW RATE =========
  const handleSort = (e) => {
    const filterValue = e.target.value;

    if (filterValue === "high") {
      const filterData = ExploreAllNft.filter((item) => item.currentBid >= 6);

      setData(filterData);
    }

    if (filterValue === "mid") {
      const filterData = ExploreAllNft.filter(
        (item) => item.currentBid >= 5.5 && item.currentBid < 6
      );

      setData(filterData);
    }

    if (filterValue === "low") {
      const filterData = ExploreAllNft.filter(
        (item) => item.currentBid >= 4.89 && item.currentBid < 5.5
      );

      setData(filterData);
    }
  };

  return (
    <>
      <section>
        <Container>
          <Row>
            <Col lg="12" className="mb-5">
              <div className="market__product__filter d-flex align-items-center justify-content-between">
                {/* <div className="filter__left d-flex align-items-center gap-5">
                  <div className="all__category__filter gap-2">
                    <Category />
                  </div>

                  <div className="all__items__filter">
                    <select onChange={handleItems}>
                      <option>All Items</option>
                      <option value="single-item">Single Item</option>
                      <option value="bundle">Bundle</option>
                    </select>
                  </div>
                </div> */}
                {/* 
                <div className="filter__right">
                  <select onChange={handleSort}>
                    <option>Sort By</option>
                    <option value="high">High Rate</option>
                    <option value="mid">Mid Rate</option>
                    <option value="low">Low Rate</option>
                  </select>
                </div> */}
              </div>
            </Col>

            {data?.map((item) => (
              <Col lg="3" md="4" sm="6" className="mb-4" key={item.id}>
                <NftCard item={item} />
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Explore;
