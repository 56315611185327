import React, { useEffect, useState } from "react";
import { connectWallet, getCurrentWalletConnected } from "../utils/interact";
import { walletSlice } from "../Slices/Wallet";
import { useDispatch, useSelector } from "react-redux";
import CommonSection from "../components/ui/Common-section/CommonSection";
import { Container, Row, Col } from "reactstrap";

import "../styles/wallet.css";
require("dotenv").config();

const wallet__data = [
  {
    title: "Bitcoin",
    desc: "Bitcoin is a decentralized digital currency that you can buy, sell and exchange directly,",
    icon: "ri-bit-coin-line",
  },

  {
    title: "Coinbase",
    desc: "Coinbase is the easiest place to buy and sell cryptocurrency. Sign up and get started today.",
    icon: "ri-coin-line",
  },

  {
    title: "Metamask",
    desc: "Available as a browser extension and as a mobile app, MetaMask equips you with a key vault, secure.",
    icon: "ri-money-cny-circle-line",
  },

  {
    title: "Authereum",
    desc: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Accusantium accusamus repellat rerum consequ!",
    icon: "ri-bit-coin-line",
  },
];

const Wallet = () => {
  const imageDetail = useSelector((state) => state.image.imageDetail);
  console.log("inside FileUpload" + imageDetail);
  const dispatch = useDispatch();
  const walletActions = walletSlice.actions;
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const statee = {
    imgPreview: "Image preview",
  };

  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          dispatch(walletActions.setWalletAddress(accounts[0]));

          setStatus("👆🏽 Write a message in the text-field above.");
        } else {
          setWallet("");
          setStatus("🦊 Connect to Metamask using the top right button.");
        }
      });
    } else {
      setStatus(
        <p>
          {" "}
          🦊{" "}
          <a target="_blank" href={`https://metamask.io/download.html`}>
            You must install Metamask, a virtual Ethereum wallet, in your
            browser.
          </a>
        </p>
      );
    }
  }

  useEffect(async () => {
    const { address, status } = await getCurrentWalletConnected();
    console.log("inside wallet.js");
    setWallet(address);
    dispatch(walletActions.setWalletAddress(address));

    setStatus(status);
    addWalletListener();
  }, []);

  const connectWalletPressed = async () => {
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
    dispatch(walletActions.setWalletAddress(walletResponse.address));
    console.log("Connected Address" + walletResponse.address);
  };

  return (
    <>
      <CommonSection title="Connect Wallet" />
      <section>
        <Container>
          <Row>
            <Col lg="12" className="mb-5 text-center">
              <div className="w-50 m-auto">
                <h3 className="text-light">Connect your wallet</h3>
                <p className="wallet__description">
                  Connect with one of our available wallet providers or create a
                  new one.
                </p>
              </div>
            </Col>

            {wallet__data.map((item, index) => (
              <Col
                onClick={connectWalletPressed}
                lg="3"
                md="4"
                sm="6"
                key={index}
                className="mb-4"
              >
                <div className="wallet__item">
                  <span>
                    <i class={item.icon}></i>
                  </span>
                  <h5>{item.title}</h5>
                  <h6 className="wallet__description">{item.desc}</h6>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Wallet;
