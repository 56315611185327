import { createSlice } from "@reduxjs/toolkit";

export const nftSlice = createSlice({
  name: "nft",
  initialState: {
    AllProfiles: [" "],
    OwnerAddress: "",
    AllNft: [""],

    NftData: {},
  },

  reducers: {
    setAllProfiles: (state, action) => {
      state.AllProfiles = action.payload.data.ownersAddress;
      console.log("All Profiles inside nftSlice" + state.AllProfiles);
    },
    setOwnerAddress: (state, action) => {
      state.OwnerAddress = action.payload;
      console.log("Owner Addres" + state.OwnerAddress);
    },
    setAllNft: (state, action) => {
      state.AllNft = action.payload.data.data.Vouchers;

      console.log("All Nft" + state.AllNft);
    },
    setNftData: (state, action) => {
      state.NftData = action.payload;
      const jsonNft = JSON.stringify(state.NftData);
      console.log("All Nft" + state.NftData.tokenId);
    },
  },
});
