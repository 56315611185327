import React from "react";

import { Routes, Route, Navigate } from "react-router-dom";

import Home from "../pages/Home";
import Market from "../pages/Market";
import Create from "../pages/Create";
import Contact from "../pages/Contact";
import Setting from "../components/profile/dashboard/Setting";
import Wallet from "../pages/Wallet";
import NftDetails from "../pages/NftDetails";
import NftDetail from "../pages/NftDetail/NftDetail";
import Dashboard from "../components/profile/dashboard/Dashboard";
import Review from "../components/profile/review/Review";
import List from "../components/profile/list/List";
import Order from "../components/profile/order/Order";
import Earning from "../components/profile/earning/Earning";
import HomeDashboard from "../components/profile/home/Collection";
import CreatedNftDetail from "../pages/createdNftDetail/CreatedNftDetail";

const Routers = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/home" />} />
      <Route path="/home" element={<Home />} />
      <Route path="/market" element={<Market />} />
      <Route path="/create" element={<Create />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/wallet" element={<Wallet />} />
      <Route path="/nftdetail" element={<NftDetails />} />
      <Route path="/createdNftDetail" element={<CreatedNftDetail />} />
      <Route path="/dashboard" element={<Dashboard />}>
        <Route path="store" element={<List />} />
        <Route path="order" element={<Order />} />
        <Route path="review" element={<Review />} />
        <Route path="earning" element={<Earning />} />
        <Route path="product" element={<HomeDashboard />} />
      </Route>
      <Route path="setting" element={<Setting />} />
      {/* <Route path="/profile" element={<Dashboard />} /> */}
    </Routes>
  );
};

export default Routers;
