import { createSlice } from "@reduxjs/toolkit";
export const imageSlice = createSlice({
  name: "imagedata",
  initialState: {
    imageDetail: {
      title: "",
      imageUrl: "",
      description: "",
      externallink: "",
      category: "Trending",
      typeofsale: "fixed",
      fixed: {
        price: "",
        makeoffer: false,
        endingdate: "",
      },
      auction: {
        startingbid: "",
        endingdateauction: "",
      
      },
    },
  },

  reducers: {
    setTitle: (state, action) => {
      state.imageDetail.title = action.payload;
      console.log("Title" + state.imageDetail.title);
    },
    setTypeofsale: (state, action) => {
      state.imageDetail.typeofsale = action.payload;
      console.log("typeofsale" + state.imageDetail.typeofsale);
    },
    setName: (state, action) => {
      state.imageDetail.name = action.payload;
      console.log("Name" + state.imageDetail.name);
    },
    setPrice: (state, action) => {
      state.imageDetail.fixed.price = action.payload;
      console.log("fixed Price" + state.imageDetail.fixed.price);
    },
    setOffer: (state, action) => {
      state.imageDetail.fixed.makeoffer = action.payload;
      console.log("Offer" + state.imageDetail.fixed.makeoffer);
    },
    setEndingdate: (state, action) => {
      state.imageDetail.fixed.endingdate = action.payload;
      console.log("Ending Date" + state.imageDetail.fixed.endingdate);
    },
    setStartingbid: (state, action) => {
      state.imageDetail.auction.startingbid = action.payload;
      console.log("starting bid" + state.imageDetail.auction.startingbid);
    },
    setEndingdateauction: (state, action) => {
      state.imageDetail.auction.endingdateauction = action.payload;
      console.log("Ending Date auction" +   state.imageDetail.auction.endingdateauction);
    },
    // setAuctionlengthdays: (state, action) => {
    //   state.imageDetail.auction.auctionlength.days = action.payload;
    //   console.log(
    //     "Auction Length days" + state.imageDetail.auction.auctionlength.days
    //   );
    // },
    // setAuctionlengthhours: (state, action) => {
    //   state.imageDetail.auction.auctionlength.hours = action.payload;
    //   console.log(
    //     "Auction Length hours" + state.imageDetail.auction.auctionlength.hours
    //   );
    // },

    setImgUrl: (state, action) => {
      state.imageDetail.imageUrl = action.payload;
      console.log(
        "Image Url from inside imageSlice" + state.imageDetail.imageUrl
      );
    },
    setDescription: (state, action) => {
      state.imageDetail.description = action.payload;
      console.log("Description" + state.imageDetail.description);
    },
    setExternalLink: (state, action) => {
      state.imageDetail.externallink = action.payload;
      console.log("Externallink" + state.imageDetail.externallink);
    },
    setCategory: (state, action) => {
      state.imageDetail.category = action.payload;
      console.log("Category" + state.imageDetail.category);
    },

    onFileChange: (state, action) => {
      state.setFile.imgePreview = action.payload;
    },
  },
});
