import { createSlice } from "@reduxjs/toolkit";

export const categorySlice = createSlice({
  name: "nft",
  initialState: {
    AllCategory: [" "],
    CategoryType: "",
  },

  reducers: {
    setAllCategory: (state, action) => {
      state.AllCategory = action.payload.data.listOfCategory;
      console.log("All category inside categorySlice" + state.AllCategory);
    },
    setCategoryType: (state, action) => {
      state.CategoryType = action.payload;
      console.log("Category Type" + state.CategoryType);
    },
  },
});
